import * as React from "react"

import Seo from "../components/seo"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from "../images/logo.svg";
import illicitBurgersLogo from "../images/illicit-burgers-logo.svg";

library.add(fas, fab)

// styles
const logoStyles = {
  maxWidth: 400,
  width: '100%',
  height: 'auto',
  zIndex: 10,
}


// markup
const IndexPage = () => {
  return (
    <main>
      <Seo title="Quality homemade bagels in Lagos Portugal" description="Your go-to destination for quality homemade bagels in Lagos, Portugal" />
      
      <section className="header bg-primary grid lg:grid-cols-2">
        <div className="bg-white lg:bg-transparent relative col-span-1 flex justify-center p-8 lg:p-12">
          <div className="header-bg hidden lg:block"></div>
          <img src={logo} className="h-auto" alt="Pom Pom Bagels" width="400" height="400" style={logoStyles} />
        </div>
        <div className="col-span-1 px-4 py-8 lg:p-16">
          <h1 className="text-center">
            Pom Pom Bagels
          </h1>
          <div className="text-center">
            <p>Welcome to Pom Pom Bagels; your go-to destination for quality homemade bagels in Lagos Portugal.</p>
            <p>We offer a variety of bagels, toppings and fillings to cater for every taste preference with a strong focus on plant based options. Check out our full menu below for a detailed breakdown of our diverse offering.</p>
            <p>When it comes to tasty bagels in Lagos Portugal, look no further than Pom Pom Bagels!</p>
            <p>For those of you who want to make your own delicious bagels at home, please feel free to pre-order packages of unfilled bagels from us for in-store pickup.</p>
          </div>
          <div className="flex flex-wrap justify-center">
            <a href={'/pom-pom-bagels-menu-en.pdf'} className="button w-full md:w-auto" aria-label="View our English menu here in PDF format" target="_blank" rel="noopener noreferrer">View our menu (EN)</a>
            <a href={'/pom-pom-bagels-menu-pt.pdf'} className="button w-full md:w-auto" aria-label="Veja o nosso menu em PDF" target="_blank" rel="noopener noreferrer">Veja o nosso menu (PT)</a>
          </div>
          <hr />
          <div className="text-center mt-8">
            <h2 className="flex justify-center items-center">Find us at <FontAwesomeIcon icon={['fas', 'map-marked-alt']} size="xs" className="ml-4" /></h2>
            <p>
              <a
                href="https://www.google.com/maps/place/Pom+Pom+Bagels/@37.1000287,-8.6729844,15z/data=!4m5!3m4!1s0x0:0x500affc3592df16a!8m2!3d37.1000287!4d-8.6729844"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="View our location on Google Maps">
                R. Prof. Luis de Azevedo 1<br />
                8600-583 Lagos<br />
                Portugal
              </a>
            </p>
            <p>
              Email: <a href="mailto:admin@pompombagels.com" rel="noopener">admin@pompombagels.com</a><br />
              Tel: <a href="tel:(+351) 925 041 134" rel="noopener">(+351) 925 041 134</a>
            </p>
            <p>
              <a
                href="https://www.facebook.com/pompombagels/"
                className="mx-2 text-lg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to Pom Pom Bagels Facebook">
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </a>
              <a
                href="https://www.instagram.com/pom_pom_bagels/"
                className="mx-2 text-lg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to Pom Pom Bagels Instagram">
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
            </p>
          </div>
          <hr />
          <div className="flex flex-wrap justify-center items-center lg:mt-8">
            <img src={illicitBurgersLogo} className="mb-4 max-w-full w-32 h-auto" width="160" height="154" alt="Pom Pom Bagels" />
            <div className="px-4 lg:px-8 text-center max-w-full w-42 md:w-80">
              <p className="text-xs mb-4">Looking for a tasty bite to eat in the evening? Illicit Burgers serves mouth-watering gourmet burgers from the same location every night of the week.</p>
              <p className="mb-0">
                <a
                  href="https://illicitburgers.com"
                  className="text-xs"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Go to our sister cafe's website: illicitburgers.com">illicitburgers.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-wrap justify-center">
        <div className="bg-secondary w-full lg:w-1/2 p-6 lg:p-16">
          <p>Although we do offer a couple of meat based menu options our bagels are fully plant-based. Our tasty toppings and delicious fillings consist of ingredients that are locally sourced and free from preservatives.</p>
          <p>We pride ourselves on providing locals and visitors with Montreal style chunky bagels made fresh in-house everyday, that are as healthy as they are delicious.</p>
          <p>Oh, who is "we"? Well, like many people, Katie Pomeroy stumbled upon baking during the 2020 lockdown, and even though the first few batches left a lot to be desired, the demand for these tasty treats grew so quickly and it was only a matter of time before she started her own seaside bagel shop.</p>
          <p className="mb-0">You’ll find Katie in the shop from 8am-3pm most days of the week, helping her colleagues prepare the most delicate and satisfying bagels you’ll find anywhere in the Algarve. Pop in for a bagel, say hi and enjoy the laid-back vibes!</p>
        </div>
        <div className="w-full lg:w-1/2 p-6 lg:p-16">
          <h3>Our Story</h3>
          <p>The concept of Pom Pom Bagels was born out of a series of 2020 lockdown baking sessions with close friends, the long-term motive stretches a little deeper.</p>
          <p>We absolutely love it here in Lagos and to continue enjoying this beautiful Algarve lifestyle, it is super important to us to care for and look after our planet. A few things we’re doing to help:</p>
          <ul>
            <li>We use compostable and/or biodegradable packaging</li>
            <li>We work with the awesome project @the_compost_revolution by giving our food waste to them to make compost</li>
            <li>We have a ´bring your own cup´ incentive in place, which will give discounts on drinks and save on packaging waste</li>
            <li>Carbon offsetting - this means we will use projects to compensate for every tonne of CO2 we emit by ensuring there is one tonne less in the atmosphere.</li>
            <li>Support locally with buying ingredients, supporting charities and projects such as beach clean ups.</li>
          </ul>
          <p className="mb-0">Our hope is that Pom Pom Bagels will equip us with the tools to slowly but surely increase awareness of plastic pollution, carbon footprints and unnecessary waste. We are always open to new ideas with environmental projects and would love your input, don’t hesitate to get in touch!</p>
        </div>
      </section>

      <section className="flex justify-center p-6">
        <p className="mb-0">View our <a href="/privacy-policy">Privacy Policy</a>.</p>
      </section>
    </main>
  )
}

export default IndexPage
